<template>
  <b-overlay
      :show="Coupons === null"
      rounded="sm"
  >
    <div v-if="Coupons !== null">
      <b-modal
          id="modal-delete"
          centered
          ok-title="بله"

          cancelTitle="خیر"
          @ok="DeleteCoupon"
      >
        <span>آیا از حذف کردن این کوپن اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          centered
          ok-title="بروزرسانی کوپن"
          ok-only
          @ok.preventDefault()="UpdateCoupon"
      >
        <b-row v-if="SelectedCoupon">
          <b-col cols="12">
            <b-form-group>
              <label for="email">کد فعالسازی کوپن</label>
              <b-form-input
                  type="text"
                  placeholder="ex: FURFIT2022"
                  v-model="SelectedCoupon.code"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">توضیحات کوپن</label>
              <b-form-input
                  type="text"
                  placeholder="ex: FURFIT2022"
                  v-model="SelectedCoupon.condition"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>مقدار تخفیف</label>
            <input type="number" v-model="SelectedCoupon.value" class="form-control">
          </b-col>
          <b-col md="6">
            <label>نوع تخفیف</label>
            <select class="form-control" v-model="SelectedCoupon.type">
              <option :value="1">درصدی</option>
              <option :value="2">عددی</option>
            </select>
          </b-col>
          <b-col md="6">
            <label>تعداد</label>
            <input type="number" v-model="SelectedCoupon.count" class="form-control">
          </b-col>
          <b-col md="6">
            <label>تاریخ انقضا</label>
            <date-picker v-model="SelectedCoupon.expireDate"></date-picker>
          </b-col>
        </b-row>


      </b-modal>
      <b-modal
          id="modal-Add"
          centered
          ok-title="افزودن کوپن"
          ok-only

          @ok.preventDefault()="CreateCoupon"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="email">کد فعالسازی کوپن</label>
              <b-form-input
                  type="text"
                  placeholder="ex: FURFIT2022"
                  v-model="coupon.code"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">توضیحات کوپن</label>
              <b-form-input
                  type="text"
                  placeholder="ex: FURFIT2022"
                  v-model="coupon.condition"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>مقدار تخفیف</label>
            <input type="number" v-model="coupon.value" class="form-control">
          </b-col>
          <b-col md="6">
            <label>نوع تخفیف</label>
            <select class="form-control" v-model="coupon.type">
              <option :value="1">درصدی</option>
              <option :value="2">عددی</option>
            </select>
          </b-col>
          <b-col md="6">
            <label>تعداد</label>
            <input type="number" v-model="coupon.count" class="form-control">
          </b-col>
          <b-col md="6">
            <label>تاریخ انقضا</label>
            <date-picker v-model="coupon.expireDate"></date-picker>
          </b-col>
        </b-row>


      </b-modal>
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >
        <b-col lg="12" md="12" class="my-2">
          <b-row>
            <b-col lg="2" md="12" class="my-2">
              <b-button

                  variant="success"
                  v-b-modal.modal-Add

              >
                افزودن کوپن جدید
              </b-button>
            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="Coupons"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: delete -->
          <template #cell(value)="data">

            <div class="d-flex  flex-column justify-content-center">
              <small>{{  Intl.NumberFormat('fa-IR').format(data.item.value)}} تومان</small>
              <div>

              <b-badge
                  v-if="data.item.type===1"
                  pill
                  class="mr-75"
                  variant="primary"
              >
               درصدی
              </b-badge>
              <b-badge
                  v-if="data.item.type===2"
                  pill
                  class="mr-75"
                  variant="primary"
              >
                عددی
              </b-badge>

              </div>
            </div>

        </template>
          <template #cell(expireDate)="data">

           <small>{{new Date(data.item.expireDate).toLocaleDateString('fa-IR')}}</small>

          </template>
          <template #cell(Delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="SetSelectedCoupon(data.item)"
            >
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </div>

          </template>

          <template #cell(Edit)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-edit
                 @click="SetSelectedCoupon(data.item)"
            >
              <feather-icon class="text-primary" icon="EditIcon" size="20"/>
            </div>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-end "
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {CreateCoupon, DeleteCoupon, GetCouponByCode, GetAllCoupon, UpdateCoupon} from "@/libs/Api/Coupons";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import helper from "@/libs/Helper";

export default {
  title: 'لیست کوپن ها  - پنل ادمین انزا مد',
  name: 'Coupons',
  data() {
    return {
      Coupons: null,
      totalCount: null,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'couponId',
          label: 'شناسه'
        },
        {
          key: 'code',
          label: 'کد'
        },
        {
          key: 'value',
          label: 'میزان و نوع تخفیف'
        },
        {
          key: 'count',
          label: 'تعداد کل'
        },
        {
          key: 'usedCount',
          label: 'تعداد استفاده شده'
        },
        {
          key: 'expireDate',
          label: 'تاریخ انقضا'
        },
        {
          key: 'Delete',
          label: 'حذف'
        },
        {
          key: 'Edit',
          label: 'ویرایش'
        },
        // { key: 'parentId'},
      ],
      coupon: {
        code: '',
        condition: '',
        count: null,
        type: null,
        value: null,
        expireDate: ''
      },
      currentPage: 1,
      perPage: 10,
      SelectedCoupon: null,

    }
  },
  async created() {
    await this.GetAllCoupon()
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {

    currentPage: function () {
      this.GetAllCoupon()
    }
  },
  methods: {
    async UpdateCoupon() {
      let _this = this
      let updateCoupon = new UpdateCoupon(_this)

      updateCoupon.setData(_this.SelectedCoupon)
      await updateCoupon.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کوپن بروزرسانی شد`,
          },
        })
        _this.SelectedCoupon = null

        _this.GetAllCoupon();
      }, function (error) {
        console.log(error)
      })
    },
    async CreateCoupon() {
      let _this = this

      if (_this.coupon.code !== '' && _this.coupon.count && _this.coupon.type && _this.coupon.value && _this.coupon.expireDate !== '') {

        let createCoupon = new CreateCoupon(_this)

        createCoupon.setData(_this.coupon)
        await createCoupon.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `کوپن ساخته  شد`,
            },
          })
          _this.GetAllCoupon();
          _this.coupon = {
            code: '',
            condition: '',
            count: null,
            type: null,
            value: null,
            date: ''
          }
        }, function (error) {
          console.log(error)
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا همه فیلد ها را پر کنید`,
          },
        })
      }


    },
    async DeleteCoupon() {
      let _this = this
      let deleteCoupon = new DeleteCoupon(_this)
      deleteCoupon.setParams({id: this.SelectedCoupon.couponId})
      await deleteCoupon.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کوپن  حذف شد.`,
          },
        })
        _this.GetAllCoupon();
      }, function (error) {
        console.log(error)
      })
    },
    SetSelectedCoupon(item) {
      this.SelectedCoupon = JSON.parse(JSON.stringify(item))
    },
    async GetAllCoupon() {
      let _this = this
      let getAllCoupon = new GetAllCoupon(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: 10
      }
      getAllCoupon.setParams(data)
      await getAllCoupon.fetch(function (content) {
        _this.Coupons = content.coupons
        _this.totalCount = content.couponsCount
      }, function (error) {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
